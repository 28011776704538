import React, {FormEvent, useEffect, useState} from "react";
import {Button, Container, Form, FormField, Header, Input, SpaceBetween} from "@amzn/awsui-components-react/polaris";

interface SetOtpPinFormState {
    pin: string,
    pinError: string,
    confirmPin: string,
    confirmPinError: string,
    hasSubmittedAtLeastOnce: boolean
}

const initialFormState: SetOtpPinFormState = {
    pin: "",
    pinError: "",
    confirmPin: "",
    confirmPinError: "",
    hasSubmittedAtLeastOnce: false
}

const asciiRegex = /^[!-~]{8,}$/;

export const SetOtpPinForm = ({loggedInUsername, error, onSubmit}: SetOtpPinFormProps) => {
    const [formState, setFormState] = useState(initialFormState)

    useEffect(() => {
        let pinError = ""
        if (formState.pin.length === 0) {
            pinError = "Pin is required"
        } else if (formState.pin.length < 8) {
            pinError = "Pin must be at least 8 characters long"
        } else if (!asciiRegex.test(formState.pin)) {
            pinError = "Pin can consist of only ASCII characters"
        }

        let confirmPinError = ""
        if (formState.confirmPin.length === 0) {
            confirmPinError = "Please confirm your pin"
        } else if (formState.confirmPin !== formState.pin) {
            confirmPinError = "Pins do not match"
        }

        if (formState.pinError !== pinError || formState.confirmPinError !== confirmPinError) {
            setFormState({...formState, pinError: pinError, confirmPinError: confirmPinError})
        }

    }, [formState]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!(formState.pinError || formState.confirmPinError)) {
            onSubmit(formState.pin);
            setFormState(initialFormState)
        } else {
            // per Cloudscape design best practices, form validation errors should only be shown after the user first
            // attempts to submit, and then after that validation should be continuous on all form changes
            setFormState({...formState, hasSubmittedAtLeastOnce: true});
        }
    }

    return (
        <Container header={<Header variant="h2">Set your OTP security pin</Header>}>
            <form style={{margin: '16px'}} onSubmit={handleSubmit}>
                <Form
                    actions={<Button variant="primary" data-testid="set-button">Set</Button>}
                    errorText={error}
                    data-testid="set-pin-form"
                >
                    <SpaceBetween direction="vertical" size="s">
                        <p data-testid="form-info">
                            You are setting an OTP pin for <strong>{loggedInUsername}</strong>. Enter and confirm your
                            desired pin and then click the Set button.
                        </p>
                        <FormField label="New pin" errorText={formState.hasSubmittedAtLeastOnce ? formState.pinError : ""} data-testid="new-pin-form-field">
                            <Input type="password" value={formState.pin} onChange={(e) => setFormState({...formState, pin: e.detail.value.trim()})} data-testid="new-pin-input"/>
                        </FormField>
                        <FormField label="Confirm pin" errorText={formState.hasSubmittedAtLeastOnce ? formState.confirmPinError: ""} data-testid="confirm-pin-form-field">
                            <Input type="password" value={formState.confirmPin} onChange={(e) => setFormState({...formState, confirmPin: e.detail.value.trim()})} data-testid="confirm-pin-input"/>
                        </FormField>
                </SpaceBetween>
                </Form>
            </form>
        </Container>
    )
}