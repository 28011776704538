import React from 'react';
import {SideNavigation, SideNavigationProps} from '@amzn/awsui-components-react/polaris';

// for now, while we have few pages with simple paths, we can use window.location.pathname (with trailing slashes
// removed, since /page and /page/ are the same) to set the current active ref; as we add more pages or more complex
// navigation item hrefs, consider exposing activeHref as a prop
const activeHref = window.location.pathname.replace(/\/+$/, '');

export const DefaultSideNavigation = ({otpEnabled}: SideNavProps) => {
    const defaultNavHeader = {text: 'Kuiper IdP', href: '/'};
    const navItems: SideNavigationProps.Item[] = [
        {type: 'link', text: 'Register security key', href: '/register'},
    ]
    if (otpEnabled) {
        navItems.push(
            {type: 'link', text: 'Register OTP security key', href: '/register-otp-key'},
            {type: 'link', text: 'Set OTP security pin', href: '/set-otp-pin'}
        )
    }

    const defaultNavItems: SideNavigationProps['items'] = [
        {
            type: 'section',
            text: 'Key Management',
            items: navItems,
        }
    ]

    return <SideNavigation items={defaultNavItems} header={defaultNavHeader} activeHref={activeHref}/>;
}

export const AdminSideNavigation = () => {
    const adminPathPrefix = "/admin";
    const adminNavHeader = {text: 'Kuiper IdP Admin', href: adminPathPrefix};
    const adminNavItems: SideNavigationProps['items'] = [
        {
            type: 'link',
            text: 'User Management',
            href: adminPathPrefix
        },
        {
            type: 'link',
            text: 'Key Management',
            href: "#"
        }
    ];

    return <SideNavigation items={adminNavItems} header={adminNavHeader} activeHref={activeHref}/>;
}