import React, {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {Alert, BreadcrumbGroup, ContentLayout, Header, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {useSetOTPPin} from "../hooks/otp";
import {SetOtpPinForm} from "../components/set-otp-pin-form";

const Breadcrumbs = () => {
    const breadcrumbItems = [
        {href: '#', text: 'Key Management'},
        {href: '/registerOtp', text: 'Set OTP security pin'},
    ];

    return (
        <BreadcrumbGroup
            items={breadcrumbItems}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
        />
    )
}

const ShowSetAlerts = (items: RegisterAlerts) => {
    return (
        <SpaceBetween direction="vertical" size="xs">
            {Object.keys(items).map((key: string) => (
                <Alert key={key} statusIconAriaLabel={items[key].ariaLabel} type={items[key].type}>
                    {items[key].message}
                </Alert>
            ))}
        </SpaceBetween>
    );
}

export const SetOtpPin = () => {
    const auth = useAuth();
    const [alerts, setAlerts] = useState<RegisterAlerts>({})
    const {setPin, pinError, isPinSuccess } = useSetOTPPin();

    useEffect(() => {
        if (isPinSuccess) {
            setAlerts(alerts => ({
                ...alerts, "successfully_registered_alert": {
                    message: "Your OTP pin was successfully registered! You can now register your Yubi OTP Key",
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isPinSuccess])

    return (
        <PageLayout sideNavOpen={false} loggedInUsername={auth.user?.profile?.name} otpEnabled={auth.user?.profile?.otp_enabled as boolean ?? false}>
            <ContentLayout
                notifications={ShowSetAlerts(alerts)}
                breadcrumbs={Breadcrumbs()}
                headerVariant="high-contrast"
                header={<Header variant="h1">Security Pin Registration</Header>}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={false}
            >
                <SpaceBetween direction="vertical" size="l">
                    <SetOtpPinForm loggedInUsername={auth.user?.profile?.preferred_username} error={pinError} onSubmit={setPin}/>
                </SpaceBetween>
            </ContentLayout>
        </PageLayout>
    );
}