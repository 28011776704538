import React, {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {Alert, BreadcrumbGroup, ContentLayout, Header, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {useRegister} from "../hooks/webauthn";
import {RegisterMfaForm} from "../components/register-mfa-form";

const Breadcrumbs = () => {
    const breadcrumbItems = [
        {href: '#', text: 'Key Management'},
        {href: '/register', text: 'Register security key'},
    ];

    return (
        <BreadcrumbGroup
            items={breadcrumbItems}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
        />
    )
}

const ShowRegisterAlerts = (items: RegisterAlerts) => {
    return (
        <SpaceBetween direction="vertical" size="xs">
            {Object.keys(items).map((key: string) => (
                <Alert key={key} statusIconAriaLabel={items[key].ariaLabel} type={items[key].type}>
                    {items[key].message}
                </Alert>
            ))}
        </SpaceBetween>
    );
}



export const RegisterMFA = () => {
    const auth = useAuth();
    const [alerts, setAlerts] = useState<RegisterAlerts>({})
    const {register, error, isSuccess } = useRegister();

    useEffect(() => {
        if (!window.chrome) {
            setAlerts(prevAlerts => ({
                ...prevAlerts, "browser_compatability_warning": {
                    message: "There are known issues with registering FIDO2 keys in this browser. Please use Chrome for the best registration experience.",
                    ariaLabel: "Warning",
                    type: "warning",
                }
            }));
        }
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setAlerts(prevAlerts => ({
                ...prevAlerts, "successfully_registered_alert": {
                    message: "Your MFA token was successfully registered! You can now access the Kuiper network.",
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isSuccess])

    return (
        <PageLayout sideNavOpen={false} loggedInUsername={auth.user?.profile?.name} otpEnabled={auth.user?.profile?.otp_enabled as boolean ?? false}>
            <ContentLayout
                notifications={ShowRegisterAlerts(alerts)}
                breadcrumbs={Breadcrumbs()}
                headerVariant="high-contrast"
                header={<Header variant="h1">Security Key Registration</Header>}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={false}
            >
                <RegisterMfaForm loggedInUsername={auth.user?.profile?.preferred_username} error={error} onSubmit={register}/>
            </ContentLayout>
        </PageLayout>
    );
}