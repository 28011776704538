import { getAppConfig } from "../config";
import { User } from "oidc-client-ts";

export function getUserAccessToken() {
  const appConfig = getAppConfig();
  const oidcStorage = localStorage.getItem(
    `oidc.user:${appConfig.openId.authority}:${appConfig.openId.client_id}`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage).access_token;
}
