import React, {FormEvent, useEffect, useState} from "react";
import {Button, Container, Form, FormField, Header, Input, SpaceBetween} from "@amzn/awsui-components-react/polaris";

interface RegisterOtpKeyFormState {
    pin: string,
    pinError: string,
    otp: string,
    otpError: string,
    hasSubmittedAtLeastOnce: boolean
}

const initialFormState: RegisterOtpKeyFormState = {
    pin: "",
    pinError: "",
    otp: "",
    otpError: "",
    hasSubmittedAtLeastOnce: false
}

export const RegisterOtpKeyForm = ({loggedInUsername, error, onSubmit}: RegisterOtpKeyFormProps) => {
    const [formState, setFormState] = useState(initialFormState)

    useEffect(() => {
        let pinError = ""
        if (formState.pin.length === 0) {
            pinError = "Pin is required"
        } else if (formState.pin.length < 8) {
            pinError = "Invalid pin length"
        }

        let otpError = ""
        if (formState.otp.length === 0) {
            otpError = "OTP is required"
        } else if (formState.otp.length !== 44) {
            otpError = "Invalid OTP length"
        }
        if (formState.pinError !== pinError || formState.otpError !== otpError) {
            setFormState({...formState, pinError: pinError, otpError: otpError})
        }
    }, [formState]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!(formState.pinError || formState.otpError)) {
            onSubmit(formState.pin, formState.otp);
            setFormState(initialFormState)
        } else {
            // per Cloudscape design best practices, form validation errors should only be shown after the user first
            // attempts to submit, and then after that validation should be continuous on all form changes
            setFormState({...formState, hasSubmittedAtLeastOnce: true});
        }
    }

    return (
        <Container header={<Header variant="h2">Register a new OTP security key</Header>}>
            <form onSubmit={handleSubmit}>
                <Form
                    actions={<Button variant="primary" data-testid="register-button">Register</Button>}
                    errorText={error}
                    data-testid="register-otp-form"
                >
                    <SpaceBetween direction="vertical" size="s">
                        <p data-testid="form-info">
                            You are registering a OTP security key for <strong>{loggedInUsername}</strong>. Enter your OTP pin and then long-press your YubiKey.
                        </p>
                        <FormField label="Pin" errorText={formState.hasSubmittedAtLeastOnce ? formState.pinError: ""} description="Enter your OTP pin" data-testid="pin-form-field">
                            <Input type="password" value={formState.pin} onChange={(e) => setFormState({...formState, pin: e.detail.value.trim()})} data-testid="pin-input"/>
                        </FormField>
                        <FormField label="OTP" errorText={formState.hasSubmittedAtLeastOnce ? formState.otpError: ""} description="Press and hold your YubiKey for 3-5 seconds" data-testid="otp-form-field">
                            <Input type="password" value={formState.otp} onChange={(e) => setFormState({...formState, otp: e.detail.value.trim()})} data-testid="otp-input"/>
                        </FormField>
                    </SpaceBetween>
                </Form>
            </form>
        </Container>
    )
}